
	import * as Manager from '@/Manager';
	import * as Model from '@/Model';
	import PageBase from '@/Page/Base.vue';
	import { Component } from 'vue-property-decorator';
	import { sleep } from '@/Utility/Helpers';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package Page
	 * @project ct-innovation-bunnydragon
	 */
	@Component
	export default class PageInterlude extends PageBase {
		/**
		 * @return number
		 */
		public get translateY(): number {
			return this.$store.state.currentMotionScrollRatio === -1 ? 0 : 100 - this.$store.state.currentMotionScrollRatio * 100;
		}

		/**
		 * @type Record<string, any>[][]
		 */
		private dialogues: Record<string, any>[][] = [
			// First play through
			[
				{
					character: 'bunny',
					pose: 'idle',
					text: `...What do we have here?`,
				},
				{
					character: 'dragon',
					pose: 'surprised',
					text: `A new ${this.$store.state.userZodiac} friend!`,
				},
				{
					character: 'bunny',
					characterPoses: {
						dragon: 'excited',
					},
					pose: 'surprised',
					text: Manager.Dialogue.introChatterForBunny,
				},
				{
					character: 'dragon',
					pose: 'happy-extra',
					text: Manager.Dialogue.introChatterForDragon,
				},
				{
					character: 'bunny',
					pose: 'happy',
					text: `I suppose you're here for a divination?`,
				},
				{
					character: 'dragon',
					pose: 'happy',
					text: `Last time I replaced Water Rabbit was 1964...`,
				},
				{
					character: 'dragon',
					pose: 'happy',
					text: `...you all were prepping for the New York World's Fair!`,
				},
				{
					character: 'bunny',
					pose: 'excited',
					text: `To receive the right fortunes and all, you'll...`,
				},
				{
					character: 'dragon',
					pose: 'sleezy',
					text: `Do the song!`,
				},
				{
					character: 'bunny',
					pose: 'happy-extra',
					text: `Ugh, we're not doing the song...`,
				},
				{
					character: 'bunny',
					pose: 'happy',
					text: `Answer these five questions, ${this.$store.state.userZodiac}.`,
				},
			],

			// Second play through
			[
				{
					character: 'bunny',
					pose: 'idle',
					text: `...Back so soon?`,
				},
				{
					character: 'dragon',
					pose: 'surprised',
					text: `Our new best ${this.$store.state.userZodiac} friend!`,
				},
				{
					character: 'bunny',
					pose: 'idle',
					text: `[Sighs] The last pick didn’t take?`,
				},
				{
					character: 'dragon',
					pose: 'surprised',
					text: `We’ll fix you up with another one in no time!`,
				},
			],

			// Third play through
			[
				{
					character: 'bunny',
					pose: 'idle',
					text: `...Listen, it’s not a contest.`,
				},
				{
					character: 'dragon',
					pose: 'surprised',
					text: `But if it was...you’d be #1!`,
				},
				{
					character: 'bunny',
					pose: 'idle',
					text: `[Huff] Let’s see what else we have back here...`,
				},
				{
					character: 'dragon',
					pose: 'surprised',
					text: `Picking prospects can be a tricky business...`,
				},
			],

			// Fourth play through
			[
				{
					character: 'bunny',
					pose: 'idle',
					text: `...You’re...persistent.`,
				},
				{
					character: 'dragon',
					pose: 'surprised',
					text: `We’re so happy to see you again!`,
				},
				{
					character: 'bunny',
					pose: 'idle',
					text: `[Pffft] I guess we'll keep searching...`,
				},
				{
					character: 'dragon',
					pose: 'surprised',
					text: `We're on a roll!`,
				},
			],

			// Fifth play through
			[
				{
					character: 'bunny',
					pose: 'idle',
					text: `...Heh. Hundredth time’s the charm.`,
				},
				{
					character: 'dragon',
					pose: 'surprised',
					text: `What’s the hurry, Rabbit?`,
				},
				{
					character: 'bunny',
					pose: 'idle',
					text: `There's no hurry. It's our cosmic duty, after all...`,
				},
				{
					character: 'dragon',
					pose: 'surprised',
					text: `Exactly, we're not giving up.`,
				},
			],
		];

		/**
		 * @return void
		 */
		protected get interactionModel(): Model.Interaction {
			const dialogueArrayIndex = (this.$store.state.timesPlayedPerVisit % this.dialogues.length) - 1;

			return new Model.Interaction({
				dialogue: this.dialogues[dialogueArrayIndex],
			});
		}

		/**
		 * @return Promise<void>
		 */
		protected async Handle_OnDialogueComplete(): Promise<void> {
			// Wait for dialogues to animate out
			await sleep(1000);

			// Go to the conversation starter
			this.$router.push({
				name: 'PageConversation',
			});
		}
	}
